<template>
  <div class="all">
    <Home2Header active-index="/about"></Home2Header>
    <AboutHeader2 active-index2="/business"></AboutHeader2>
    <div class="main">
      <div style="width: 100%;">
        <div style="float:left;width: 49.5%;position: relative" @mouseover="videoPlayback()" @mouseout="videoStopped()">
          <div id="text" style="">
            <h2>欧瑞斯</h2>
            <h3 style="margin: 5px 0;">健康触手可及</h3>
            <p style="text-indent: 2em;text-align:left;margin: 0 10% 5px 10%">
              欧瑞斯手环，您的健康生活管家！
            </p>
            <p class="videoText">
              欧瑞斯炫动手环清楚内饰上的象征，动感炫酷的互动体验。与您一起科学运动。 专属于您的私人教练。炫动手环会全方位记录你的日常生活，记录生活点滴。
            </p>
          </div>
          <img id="img" src="../../assets/Home/欧瑞斯视频封面.jpg"/>
          <video preload="none" id="video" loop muted width="100%">
            <source src="https://act-web.toodo.com.cn/official-website/video/home1.mp4" type="video/mp4">
          </video>
        </div>

        <div style="float: right;width: 49.5%;position: relative" @mouseover="videoPlayback2()"
             @mouseout="videoStopped2()">
          <div id="text2" style="">
            <h2>双动互娱</h2>
            <h3 style="margin: 10px 0;">足不出户，在家运动</h3>
            <p class="videoText">
              双动互娱是运动健康服务提供商，也是内容孵化器，包括：运动健身视频、社群互动和体感互动。为用户提供健身教学、跑步、骑行、交友、饮食指导和亲子互动娱乐。
            </p>
          </div>
          <img id="img2" src="../../assets/Home/互娱视频封面.jpg"/>
          <video preload="none" id="video2" loop muted width="100%">
            <source src="https://act-web.toodo.com.cn/official-website/video/home2.mp4" type="video/mp4">
          </video>
        </div>
        <div class="clear"></div>
      </div>

      <div style="clear: both;"></div>
      <div style="min-width: 960px;height: 320px;">
        <div style="float: left;width: 50%;padding-left: 15%;padding-right: 15%;margin-top: 50px">
          <h2 style="padding: 10px">欧瑞斯手环</h2>
          <h3 style="padding: 10px">ORAYS</h3>
          <p style="color: #6c6c6c;text-indent:2em;text-align: left">欧瑞斯手环，您的健康生活管家！</p>
          <p style="color: #6c6c6c;text-indent:2em;text-align: left">欧瑞斯炫动手环清楚内饰上的象征，动感炫酷的互动体验。与您一起科学运动。
            专属于您的私人教练。炫动手环会全方位记录你的日常生活，记录生活点滴。</p>
        </div>
        <div style="float: right;width: 50%;padding-left: 15%;padding-right: 15%;margin-top: 50px">
          <h2 style="padding: 10px">互动体感</h2>
          <h3 style="padding: 10px">ENTERTAINMENT</h3>
          <p style="color: #6c6c6c;text-indent:2em;text-align: left">
            双动互娱是运动健康服务提供商，也是内容孵化器，包括：运动健身视频、社群互动和体感互动。为用户提供健身教学、跑步、骑行、交友、饮食指导和亲子互动娱乐。</p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import AboutHeader2 from "../../components/AboutHeader2";
import Home2Header from "../../components/Home2Header";

export default {
  name: "Business",
  data() {
    return {
      opacityStyle: {opacity: 0},
      swiperOption: {
        loop: true,
        //键盘
        keyboard: {
          enabled: true,
        },
        speed: 1500,
        centeredSlides: true,
        autoplay: {
          autoplay: true,
          delay: 2000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },

    }
  },
  components: {
    Home2Header, AboutHeader2, Footer
  },
  methods: {
    videoPlayback() {
      let text = document.getElementById('text');
      let video = document.getElementById('video');
      let img=document.getElementById('img');
      text.style.opacity ="0";
      img.style.opacity="0";
      video.setAttribute("autoplay", "autoplay")
      video.play();
    },

    videoStopped() {
      let img=document.getElementById('img');
      let video = document.getElementById('video');
      let text = document.getElementById('text');
      text.style.opacity="1";
      img.style.opacity="1";
      video.pause();
    },

    videoPlayback2() {
      let text2 = document.getElementById('text2');
      let video2 = document.getElementById('video2');
      let img2=document.getElementById('img2');
      text2.style.opacity="0";
      img2.style.opacity="0";
      video2.setAttribute("autoplay", "autoplay")
      video2.play();
    },

    videoStopped2() {
      let img2=document.getElementById('img2');
      let text2 = document.getElementById('text2');
      let video2 = document.getElementById('video2');
      text2.style.opacity="1";
      img2.style.opacity="1";
      video2.pause();
    },
  },
  props: {
    activeIndex: {
      type: String
    },
    activeIndex2: {
      type: String
    }
  },
}
</script>

<style scoped>
.all {
  min-width: 960px;
}

.main {
  margin: 0 0 0 0;
}

.videoText{
  text-indent: 2em;
  text-align:left;
  margin: 0 10%;
}

@media screen and (max-width: 576px) {
  .videoText{
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .videoText{
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .videoText{
    font-size: 12px;
  }
}

@media screen and (max-width: 1200px) {
  .videoText{
    font-size: 14px;
  }
}

.clear {
  clear: both;
}

.all {
  background: white;
  margin: 0;
  padding: 0;
  min-width: 960px;
}

#text,#text2{
  position: absolute;
  z-index: 5;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  transition-duration: 0.8s;
}

#img, #img2 {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 4;
  transition-duration: 1s;
}

#img:hover, #img2:hover {
  opacity: 0;
}

.right:hover img, .downLeft:hover img, .downRight:hover img, .top:hover img {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
</style>